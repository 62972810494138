import React, { useState } from "react";
import { postApi } from "../../AxiosRequest";
import SwalAlert from "./SwalAlert";
import { Button } from "react-bootstrap";
import { decryptString } from "./cryptoUtils";
import Swal from "sweetalert2";

interface UploadAllFileProps {
  contractId: string;
  companyId: string;
  handleRefresh: () => void;
  handleUploadClose: () => void;
  UploadOtherDocument?: boolean;
  UploadOfferDocument?: boolean;
  UploadTestDocument?: boolean;
  otherDocumentType?: any;
  offerId: string;
  InsProof?: boolean;
  OnBoard?: boolean;
}

const UploadAllFile: React.FC<UploadAllFileProps> = (props) => {  
  console.log("🚀 ~ file: UploadAllFile.tsx:22 ~ props:", props)
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [selectDocType, setSelectDocType] = useState("");
  const [isUploading, setIsUploading] = useState(false); // New state to track the upload status

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      setSelectedFiles(e.target.files);
    }
  };

  const handleOtherUpload = async (e: any) => {
    e.preventDefault();
    if (!selectedFiles) {
      console.error("No file selected.");
      return;
    }
    setIsUploading(true); // Disable the button

    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }

      let param = {
        contractID: decryptString(props.contractId),
        companyID: decryptString(props.companyId),
        Doctype: selectDocType,
      };

      await postApi("/api/Contracts/UploadOtherDocument", formData, param, true)
        .then(async (response) => {
          Swal.fire(response.data, "", "success");
          props?.handleRefresh();
          props.handleUploadClose();
        })
        .catch(async (error) => {
          SwalAlert("manjierrir");
        })
        .finally(() => {
          setIsUploading(false); // Re-enable the button after upload completes
        });
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsUploading(false); // Re-enable the button in case of an error
    }
  };

  const handleTestUpload = async (e: any) => {
    e.preventDefault();
    if (!selectedFiles) {
      console.error("No file selected.");
      return;
    }
    setIsUploading(true); // Disable the button

    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }

      let param = {        
        Doctype: selectDocType,
        companyID: props.companyId
      };

      await postApi("/api/Admin/UploadData", formData, param, true)
        .then(async (response) => {
          Swal.fire(response.data, "", "success");
          props?.handleRefresh();
          props.handleUploadClose();
        })
        .catch(async (error) => {
          SwalAlert(error);
        })
        .finally(() => {
          setIsUploading(false); // Re-enable the button after upload completes
        });
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsUploading(false); // Re-enable the button in case of an error
    }
  };

  const handleOfferUpload = async (e: any, Proof?: boolean) => {    
    e.preventDefault();
    if (!selectedFiles) {
      console.error("No file selected.");
      return;
    }
    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }

      let param = {
        offerID: decryptString(props.offerId),
        companyID: decryptString(props.companyId),
        Doctype: Proof === undefined ? selectDocType : "3",
      };
      
          setIsUploading(true); // Disable the button
      await postApi("/api/Contracts/UploadOfferDocument", formData, param, true)
        .then(async (response) => {
          Swal.fire(response.data, "", "success");
          props?.handleRefresh();
          props.handleUploadClose();
        })
        .catch(async (error) => {
          SwalAlert(error);
        })
        .finally(() => {
          setIsUploading(false); // Re-enable the button after upload completes
        });
      
     } catch (error) {
        console.error("Error uploading file:", error);
        setIsUploading(false); // Re-enable the button in case of an error
      }
    };

  const handleOfferOnBoardUpload = async (e: any, OnBoard?: boolean) => {    
    e.preventDefault();
    if (!selectedFiles) {
      console.error("No file selected.");
      return;
    }
   

    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }

      let param = {
        offerID: decryptString(props.offerId),
        companyID: decryptString(props.companyId),
        Doctype: OnBoard === undefined ? selectDocType : "4",
      };
     
          setIsUploading(true); // Disable the button
          await postApi("/api/Contracts/UploadOfferDocument", formData, param, true)
          .then(async (response) => {
            Swal.fire(response.data, "", "success");
            props?.handleRefresh();
            props.handleUploadClose();
          })
          .catch(async (error) => {
            SwalAlert(error);
          })
          .finally(() => {
            setIsUploading(false); // Re-enable the button after upload completes
          });
      
    }
      catch (error) {
        console.error("Error uploading file:", error);
        setIsUploading(false); // Re-enable the button in case of an error
      }
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();
    if (!selectedFiles) {
      console.error("No file selected.");
      return;
    }
   // setIsUploading(true); // Disable the button

    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }

      let param = {
        contractID: decryptString(props.contractId),
        companyID: decryptString(props.companyId),
      };
      Swal.fire({
        timer: 8000,
        title: "Are you sure?",
        text: "Uploading signed contract will send an email to several people.",
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Upload!",
        showLoaderOnConfirm: true,
        allowEscapeKey: false,
        preConfirm: () => {
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsUploading(true); // Disable the button
      await postApi(
        "/api/Contracts/UploadSignedContract",
        formData,
        param,
        true
      )
        .then(async (response) => {
          Swal.fire(response.data, "", "success");
          props?.handleRefresh();
          props.handleUploadClose();
        })
        .catch(async (error) => {
          SwalAlert(error);
        })
        .finally(() => {
          setIsUploading(false); // Re-enable the button after upload completes
        });
    
      }
    });
    } catch (error) {
       console.error("Error uploading file:", error);
       setIsUploading(false); // Re-enable the button in case of an error
     }
   };

  return (
    <div className="container">
      {(props.UploadOtherDocument === true || props.UploadOfferDocument === true || props.UploadTestDocument) && (
        <>
          <div className="input-group">
            <span className="input-group-text">Document Type </span>
            <select
              value={selectDocType}
              onChange={(e) => setSelectDocType(e.target.value)}
              className="form-select form-select-sm"
            >
              <option value="">Select</option>
              {props?.otherDocumentType.map((item: any, index: number) => (
                <option key={index} value={item.Value}>
                  {item.Text}
                </option>
              ))}
            </select>
          </div>
          <br />
        </>
      )}

      <div className="d-flex justify-content-between">
        <input type="file" multiple onChange={handleFileChange} />
        <Button
          size="sm"
          onClick={(e) => {           
            if (props.offerId && props.UploadOfferDocument) {
              handleOfferUpload(e);
            } else if (props.offerId && props.InsProof) {
              handleOfferUpload(e, true);
            } else if (props.offerId && props.OnBoard) {
              handleOfferOnBoardUpload(e, true);
            } else if (props.UploadOtherDocument) {
              handleOtherUpload(e);
            } else if (props.UploadTestDocument) {
              handleTestUpload(e);
            } else {
              handleUpload(e);
            }
          }}
          disabled={isUploading} // Disable the button if uploading
        >
          {isUploading ? "Uploading..." : "Upload"}
        </Button>
      </div>
    </div>
  );
};

export default UploadAllFile;
