import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi, postApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import { Button } from "react-bootstrap";
import { FaEdit, FaFileUpload, FaInfoCircle, FaTrashAlt, FaUpload } from "react-icons/fa";
import Loading from "../../common/Loading";
import Toast from "../../common/Toast";
import {
  checkDateIsNull,
  printDate,
  printDateMonthName,
} from "../../common/Util";
import Swal from "sweetalert2";
import { GDSContextType } from "../../common/@types.usr";
import AuthContext from "../../common/AuthProvider";
import { encryptString } from "../../common/cryptoUtils";
import { useNavigate } from "react-router-dom";
import LimitedFieldOfferModel from "../Models/LimitedFieldOfferModel";
import UploadModel from "../Models/UploadModel";

interface IOffers {
  OfferDate: Date;
  CompanyName: string;
  LocationState: string;
  OfferID: number;
  CompanyID: number;
  ContractID: number;
  EmployeeNumber: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  EmployeeLocation: string;
  BaseSalary: number;
  DailyRate: number;
  ODContract_ProductionPercentage: number;
  ODContract_EnrolledInHealthBenefits: string;
  ODContract_PTODays: number;
  ODContract_PaidCEDays: number;
  ODContract_LegalEntity: string;
  ODContract_AgreementType: string;
  OriginalHireDate: Date;
  HireDate: Date;
  ODContract_RenewalDate: Date;
  ODContract_AutoRenew: boolean;
  ODContract_ContractSchedule: string;
  ODContract_ContractScheduleFullDescription: string;
  ODContract_ProductionThreshold: number;
  ODContract_ProductionPaymentFrequency: string;
  ODContract_AOADuesPercentage: number;
  ODContract_StateDuesPercentage: number;
  ODContract_LicensureDues: number;
  ODContract_MultipleLocations: string;
  ODContract_SecondaryLocation: string;
  Region: string;
  EmployeeGender: string;
  EmployeeBirthDate: Date;
  SeniorityDate: Date;
  TerminationDate: Date;
  Job: string;
  PayType: string;
  PayClass: string;
  ODContract_TuitionReimbursement: number;
  ODContract_CarTravelAllowanceMonthly: number;
  ODContract_SignOnBonus: number;
  ODContract_CEReimbursement: number;
  ODContract_PaidHolidays: number;
  ODContract_PLIProvision: boolean;
}

const CredentialingPLI = () => {
  const [loading, setloading] = useState(false);
  const [credentialingData, setCredentialingData] = useState<IOffers[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [message, setMessage] = useState("");
  const [toastKey, setToastKey] = useState<number>(0);
  const { auth } = useContext(AuthContext) as GDSContextType;
  const handleOtherUploadShow = useCallback(() => {    
    setshowOtherUpload(true);
  }, []);
  const [showOtherUpload, setshowOtherUpload] = useState(false);
  const [otherDocumentType, setOtherDocumentType] = useState([]);
  const [editDocData, seteditDocData] = useState<IOffers[]>([]);
  const handleOtherUploadClose = useCallback(() => {
    setshowOtherUpload(false);
  }, []);
  const OpenDocModel = (obj: any) => {
    if (typeof obj === "undefined") {
      handleOtherUploadShow();     
    } else { 
      seteditDocData([obj.data]);
      handleOtherUploadShow();
    }
  };
  useEffect(() => {
    async function populateDocumentType() {
      await getApi("/api/Common/GetLookupList?name=OtherDocument")
        .then((response) => {
          let data = response.data;
          setOtherDocumentType(data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let IsCancel = false;
    if (!IsCancel) {
      populateDocumentType();
    }
    return () => {
      IsCancel = true;
    };
  }, []);
  //model open functionality
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const [title, setTitle] = useState("");
  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const OpenModel = (obj: any) => {
    if (typeof obj === "undefined") {
      handleShow();
      setTitle("Create Offers");
    } else {
      seteditOfferData(obj.data);     
      
      handleShow();
      setTitle("View Offer Details");
    }
  };
  const [editOfferData, seteditOfferData] = useState<IOffers[]>([]);
  //

  const UpdateOfferStatus = async (
    statusval: string,
    event: {
      currentTarget: any;
      preventDefault: () => void;
    },
    obj: any
  ) => {
    event.preventDefault();   
       var msg = "Do you want to complete PLI?";       

    Swal.fire({
      // timer: 2500,
      title: msg,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        
        let parms = {
          offerId: obj.data?.OfferID,
          userId: auth?.Userdata?.UserId,
          status: statusval,
          employeeNumber: obj.data?.EmployeeNumber,
        };
        await postApi("/api/Contracts/UpdateOffersStatus", null, parms)
          .then(async (response) => {
            handleRefresh();           
            if(response.statusText=="Accepted"){
              Swal.fire(response.data);
            }else{
              setMessage(response.data);
              handleClick();
            }
          })
          .catch(async (error) => {
            SwalAlert(error);
          })
          .finally(async () => {});
      }
    });
  };
  useEffect(() => {
    async function populateOffers() {
      setloading(true);
      await getApi("/api/Credentialing/getAllPLI")
        .then((response) => {
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
            data[index].CredStartDate = printDate(data[index].CredStartDate);
            data[index].CredCompletionDate = printDate(
              data[index].CredCompletionDate
            );
          }
          setCredentialingData(data);
          setloading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  const columnDefs = [
    {
      headerName: "Employee #",
      field: "EmployeeNumber",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      maxWidth: 140,
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      maxWidth: 120,
    },
    // {
    //   headerName: "Base Rate",
    //   field: "DailyRate",
    //   filter: true,
    //   maxWidth: 120,
    // },
    {
      headerName: "Address",
      field: "EmployeeLocation",
      filter: true,
    },
    // {
    //   headerName: "State",
    //   field: "LocationState",
    //   filter: true,
    //   maxWidth: 90,
    // },
    {
      field: "View Details",
      maxWidth: 120,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            onClick={() => OpenModel(data)}
        
          >
            <i
              title={
                "View Details for " +
                data.data.EmployeeFirstName +
                " " +
                data.data.EmployeeLastName
              }
            >
              <FaInfoCircle />
            </i>
          </Button>{" "}
          
        </div>
      ),
    },
    {
      headerName: "Credentialing Status",
      field: "CredStatus",
      filter: true,
      maxWidth: 180,
    },
    {
      field: "Upload Document",
      maxWidth: 240,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            onClick={() => OpenDocModel(data)}
        
          >
            <i
              title={
                "Upload Document for " +
                data.data.EmployeeFirstName +
                " " +
                data.data.EmployeeLastName
              }
            >
              <FaFileUpload />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      field: "Action",
      maxWidth: 130,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "2px" }}
            size="sm"
            className="btn btn-primary btnLinkOther"
            onClick={(e) => UpdateOfferStatus("3", e, data)}
          >
            Complete PLI
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "Start Date",
      field: "CredStartDate",
      filter: true,
      maxWidth: 120,
    },
    {
      headerName: "PSV Completion Date",
      field: "CredCompletionDate",
      filter: true,
      maxWidth: 180,
    },
    
  ];

  
  let navigate = useNavigate();
  const OpenOfferDetailView = (obj: any) => {   
    let paramOfferID = encryptString(obj?.data?.OfferID);
       let paramCompanyID = encryptString(obj?.data?.CompanyID);
    let path =
      "/OfferDetails/" +
      paramOfferID +
          "/" +
      paramCompanyID;
    navigate(path);
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const Columns = [
    "EmployeeNumber",
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    "EmployeeLocation",
    "LocationState",
    "CredStatus",
    "CredStartDate",
    "CredCompletionDate",
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <Toast message={message} toastKey={toastKey} />
        <CHeading Title="Professional Liability Insurance (PLI)" />
        <div className="container-fluid">
          {credentialingData && (
            <CAgGrid
              Columns={Columns}
              rowData={credentialingData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="CompleteCredentialing"
            />
          )}
          {show && (
            <LimitedFieldOfferModel
              show={show}
              handleClose={handleClose}
              editData={editOfferData}
              handleRefresh={handleRefresh}
              title={title}
              isModelDisable={true}
              isButtonHideFromModel={true}
            />
          )}
          {showOtherUpload && (
        <UploadModel
          showUpload={showOtherUpload}
          companyId={encryptString(editDocData[0]?.CompanyID)}
          handleRefresh={handleRefresh}
          handleUploadClose={handleOtherUploadClose}
          title="Upload Proof of Insurance"
          UploadOfferDocument={false}
          otherDocumentType={ otherDocumentType}
          offerId = {encryptString(editDocData[0]?.OfferID)}
          InsProof={true}
        />
      )}
        </div>
      </div>
      
    </>
  );
};

export default CredentialingPLI;
