import React, { useCallback, useEffect, useMemo, useState,useContext } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi, postApi } from "../../../AxiosRequest";
import OfferModel from "../Models/OfferModel";
import { Button } from "react-bootstrap";
import { FaEdit, FaInfoCircle, FaTrashAlt } from "react-icons/fa";
import { printDate } from "../../common/Util";
import Loading from "../../common/Loading";
import Toast from "../../common/Toast";
import { useNavigate } from "react-router-dom";
import { encryptString } from "../../common/cryptoUtils";
import { GDSContextType } from "../../common/@types.usr";
import AuthContext from "../../common/AuthProvider";
import SwalAlert from "../../common/SwalAlert";
import Swal from "sweetalert2";
interface IOffers {
  OfferDate: Date;
  CompanyName: string;
  LocationState: string;
  OfferID: number;
  CompanyID: number;
  ContractID: number;
  EmployeeNumber: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  EmployeeLocation: string;
  BaseSalary: number;
  DailyRate: number;
  ODContract_ProductionPercentage: number;
  ODContract_EnrolledInHealthBenefits: string;
  ODContract_PTODays: number;
  ODContract_PaidCEDays: number;
  ODContract_LegalEntity: string;
  ODContract_AgreementType: string;
  OriginalHireDate: Date;
  HireDate: Date;
  ODContract_RenewalDate: Date;
  ODContract_AutoRenew: boolean;
  ODContract_ContractSchedule: string;
  ODContract_ContractScheduleFullDescription: string;
  ODContract_ProductionThreshold: number;
  ODContract_ProductionPaymentFrequency: string;
  ODContract_AOADuesPercentage: number;
  ODContract_StateDuesPercentage: number;
  ODContract_LicensureDues: number;
  ODContract_MultipleLocations: string;
  ODContract_SecondaryLocation: string;
  Region: string;
  EmployeeGender: string;
  EmployeeBirthDate: Date;
  SeniorityDate: Date;
  TerminationDate: Date;
  Job: string;
  PayType: string;
  PayClass: string;
  ODContract_TuitionReimbursement: number;
  ODContract_CarTravelAllowanceMonthly: number;
  ODContract_SignOnBonus: number;
  ODContract_CEReimbursement: number;
  ODContract_PaidHolidays: number;
  ODContract_PLIProvision: boolean;
}

const AcceptedOffers = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [editOfferData, seteditOfferData] = useState<IOffers[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [show, setShow] = useState(false);
  const [toastKey, setToastKey] = useState<number>(0);
  const [Pageshow, setPageshow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [message, setMessage] = useState("");
  const { auth } = useContext(AuthContext) as GDSContextType;
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const [PageTitle, setPageTitle] = useState("");
  const [offerData, setofferData] = useState<IOffers[]>([]);
  const urlElementsAction = window.location.href.split("/")[3];
  useEffect(() => {
    async function populateOffers() {
      setLoading(true);

      let url = "";
      switch (urlElementsAction) {
        case "OnBoardOffers":
          url = "/api/Offers/getAllODOffers?type=onBoard";
          setPageTitle("Onboarding Docs");
          setPageshow(false);
          break;   
          case "AcceptedOffers":
            url = "/api/Offers/getAllODOffers?type=Accept";
            setPageTitle("Accepted Offers");
            setPageshow(true);
            break;      
        
      }



      await getApi(url)
        .then((response) => {
          
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
            data[index].CredStartDate = printDate(data[index].CredStartDate);
            data[index].CredCompletionDate = printDate(
              data[index].CredCompletionDate
            );
          }
          setofferData(data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh,urlElementsAction]);
  // const UpdateOfferStatus = async (
  //   statusval: string,
  //   event: {
  //     currentTarget: any;
  //     preventDefault: () => void;
  //   },
  //   obj: any
  // ) => {
  //   event.preventDefault();   
  //      var msg = "Do you want to complete the Onboarding?";       

  //   Swal.fire({
  //     // timer: 2500,
  //     title: msg,
  //     showDenyButton: true,
  //     // showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     denyButtonText: `No`,
  //   }).then(async (result: any) => {
  //     if (result.isConfirmed) {
        
  //       let parms = {
  //         offerId: obj.data?.OfferID,
  //         userId: auth?.Userdata?.UserId,
  //         status: statusval,
  //         employeeNumber: obj.data?.EmployeeNumber,
  //       };
  //       await postApi("/api/Contracts/UpdateOffersStatus", null, parms)
  //         .then(async (response) => {
  //           handleRefresh();
  //           debugger;
  //           if(response.statusText=="Accepted"){
  //             Swal.fire(response.data);
  //           }else{
  //             setMessage(response.data);
  //             handleClick();
  //           }
           
  //         })
  //         .catch(async (error) => {
  //           SwalAlert(error);
  //         })
  //         .finally(async () => {});
  //     }
  //   });
  // };
  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };
  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };
  let navigate = useNavigate();
  const OpenOfferDetailView = (obj: any) => {   
    let paramOfferID = encryptString(obj?.data?.OfferID);
       let paramCompanyID = encryptString(obj?.data?.CompanyID);
    let path =
      "/OfferDetails/" +
      paramOfferID +
          "/" +
      paramCompanyID;
    navigate(path);
  };

  const columnDefs = [
    {
      headerName: "Employee #",
      field: "EmployeeNumber",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      maxWidth: 120,
    },
    // {
    //   headerName: "Base Rate",
    //   field: "DailyRate",
    //   filter: true,
    //   maxWidth: 100,
    // },
     {
      headerName: "Address",
      field: "EmployeeLocation",
      filter: true,
    },
    // {
    //   headerName: "State",
    //   field: "LocationState",
    //   filter: true,
    //   maxWidth: 90,
    // },
    {
      field: "View Details",
      maxWidth: 120,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
          //onClick={() => OpenModel(data)}
         //Manji - This will call the OfferDetails.tsx 
           onClick={() => OpenOfferDetailView(data)}
          >
            <i
              title={
                "View Details for " +
                data.data.EmployeeFirstName +
                " " +
                data.data.EmployeeLastName
              }
            >
              <FaInfoCircle />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "Credentialing Status",
      field: "CredStatus",
      filter: true
    },
    {
      headerName: "Start Date",
      field: "CredStartDate",
      filter: true,
      maxWidth: 120,
    },
    {
      headerName: "Completion Date",
      field: "CredCompletionDate",
      filter: true,
      maxWidth: 160,
    },
    
    // {
    //   field: "Action",
    //   maxWidth: 160,
    //   hide:Pageshow,
    //   cellRenderer: (data: any) => (
    //     <div>
    //       <Button
    //         style={{ marginLeft: "2px" }}
    //         size="sm"
    //         className="btn btn-primary btnLinkOther"
    //         onClick={(e) => UpdateOfferStatus("4", e, data)}
    //       >
    //         Complete Onboarding
    //       </Button>{" "}
    //     </div>
    //   ),
    // },
  ];

  const OpenModel = (obj: any) => {
    if (typeof obj === "undefined") {
      handleShow();
      setIsUpdate(false);
      setTitle("Create Offers");
    } else {
      seteditOfferData(obj.data);
      setIsUpdate(true);
      handleShow();
      setTitle("View Offer Details");
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const Columns = [
    "EmployeeNumber",    
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    "EmployeeLocation",
    // "LocationState",//
    "CredStatus",
    "CredStartDate",
    "CredCompletionDate",
    "ContractStatus"
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
      <Toast message={message} toastKey={toastKey} />
        <CHeading Title={PageTitle} />
        <div className="container-fluid">
          {offerData && (
            <CAgGrid
              Columns={Columns}
              rowData={offerData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              OpenModel={OpenModel}
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="AcceptedOffers"
            />
          )}
        </div>
      </div>
      {show && (
        <OfferModel
          show={show}
          handleClose={handleClose}
          editData={editOfferData}
          seteditData={seteditOfferData}
          isUpdate={isUpdate}
          handleRefresh={handleRefresh}
          title={title}
          isModelDisable={true}
          isButtonHideFromModel={true}
        />
      )}
    </>
  );
};

export default AcceptedOffers;
